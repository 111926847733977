// import PropTypes from 'prop-types';
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

import { Grid } from './styled';

const LOGOS_QUERY = graphql`
  {
    sulzer: file(relativePath: { eq: "logos/white/sulzer.png" }) {
      childImageSharp {
        fixed(width: 84, height: 23) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    narvar: file(relativePath: { eq: "logos/white/narvar.png" }) {
      childImageSharp {
        fixed(width: 75, height: 19) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    workmarket: file(relativePath: { eq: "logos/white/workmarket.png" }) {
      childImageSharp {
        fixed(width: 99, height: 21) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    radio: file(relativePath: { eq: "logos/white/radio.png" }) {
      childImageSharp {
        fixed(width: 94, height: 11) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    deloitte: file(relativePath: { eq: "logos/white/deloitte.png" }) {
      childImageSharp {
        fixed(width: 73, height: 16) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    entercom: file(relativePath: { eq: "logos/white/entercom.png" }) {
      childImageSharp {
        fixed(width: 90, height: 15) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    acciona: file(relativePath: { eq: "logos/white/acciona.png" }) {
      childImageSharp {
        fixed(width: 70, height: 30) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    bmw: file(relativePath: { eq: "logos/white/bmw.png" }) {
      childImageSharp {
        fixed(width: 35, height: 35) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    transloc: file(relativePath: { eq: "logos/white/transloc.png" }) {
      childImageSharp {
        fixed(width: 77, height: 13) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    americanairlines: file(relativePath: { eq: "logos/white/americanairlines.png" }) {
      childImageSharp {
        fixed(width: 117, height: 18) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    adp: file(relativePath: { eq: "logos/white/adp.png" }) {
      childImageSharp {
        fixed(width: 43, height: 19) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    loccitane: file(relativePath: { eq: "logos/white/loccitane.png" }) {
      childImageSharp {
        fixed(width: 94, height: 21) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    stuart: file(relativePath: { eq: "logos/white/stuart.png" }) {
      childImageSharp {
        fixed(width: 73, height: 21) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    adesa: file(relativePath: { eq: "logos/white/adesa.png" }) {
      childImageSharp {
        fixed(width: 78, height: 33) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    lufthansa: file(relativePath: { eq: "logos/white/lufthansa.png" }) {
      childImageSharp {
        fixed(width: 92, height: 19) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    vw: file(relativePath: { eq: "logos/white/vw.png" }) {
      childImageSharp {
        fixed(width: 95, height: 23) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    snaptravel: file(relativePath: { eq: "logos/white/snaptravel.png" }) {
      childImageSharp {
        fixed(width: 94, height: 14) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    iheartradio: file(relativePath: { eq: "logos/white/iheartradio.png" }) {
      childImageSharp {
        fixed(width: 68, height: 21) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    olo: file(relativePath: { eq: "logos/white/olo.png" }) {
      childImageSharp {
        fixed(width: 34, height: 21) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    lm: file(relativePath: { eq: "logos/white/lm.png" }) {
      childImageSharp {
        fixed(width: 93, height: 23) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const Logos = (props) => {
  const {
    sulzer,
    narvar,
    workmarket,
    radio,
    deloitte,
    entercom,
    acciona,
    bmw,
    transloc,
    americanairlines,
    adp,
    loccitane,
    stuart,
    adesa,
    lufthansa,
    vw,
    snaptravel,
    iheartradio,
    olo,
    lm,
  } = useStaticQuery(LOGOS_QUERY);
  return (
    <Grid
      alignItems="center"
      justifyItems="center"
      justifyContent="space-between"
      gridTemplateColumns={['1fr 1fr', '1fr 1fr 1fr 1fr']}
      gridRowGap={[30, 50]}
      width="100%"
      sx={{ '> .mobile-hide': { display: ['none !important', 'inline-block !important'] } }}
      {...props}
    >
      <Img fixed={americanairlines.childImageSharp.fixed} alt="American Airlines logo" />
      <Img fixed={deloitte.childImageSharp.fixed} alt="Deloitte logo" />
      <Img fixed={loccitane.childImageSharp.fixed} alt="L'Occitane en Provence logo" />
      <Img fixed={vw.childImageSharp.fixed} alt="Volkswagen logo" />
      <Img fixed={lufthansa.childImageSharp.fixed} alt="Lufthansa logo" />
      <Img fixed={bmw.childImageSharp.fixed} alt="BMW logo" />
      <Img fixed={iheartradio.childImageSharp.fixed} alt="iHeartRadio logo" />
      <Img fixed={lm.childImageSharp.fixed} alt="Liberty Mutual logo" />
      <Img fixed={sulzer.childImageSharp.fixed} alt="Sulzer logo" />
      <Img fixed={radio.childImageSharp.fixed} alt="Radio.com logo" />
      <Img fixed={entercom.childImageSharp.fixed} alt="Entercom logo" />
      <Img fixed={acciona.childImageSharp.fixed} alt="Acciona logo" />
      <Img fixed={transloc.childImageSharp.fixed} alt="TransLoc logo" className="mobile-hide" />
      <Img fixed={adp.childImageSharp.fixed} alt="ADP logo" className="mobile-hide" />
      <Img fixed={stuart.childImageSharp.fixed} alt="Stuart logo" className="mobile-hide" />
      <Img fixed={adesa.childImageSharp.fixed} alt="Adesa logo" className="mobile-hide" />
      <Img fixed={snaptravel.childImageSharp.fixed} alt="Snaptravel logo" className="mobile-hide" />
      <Img fixed={narvar.childImageSharp.fixed} alt="Narvar logo" className="mobile-hide" />
      <Img fixed={olo.childImageSharp.fixed} alt="Olo logo" className="mobile-hide" />
      <Img fixed={workmarket.childImageSharp.fixed} alt="Workmarket logo" className="mobile-hide" />
    </Grid>
  );
};

Logos.propTypes = {};

Logos.defaultProps = {};

export default Logos;
