import React from 'react';

import { Flex, H2, H5, Inner, Link, Outer } from '../styled';
import LatestPosts from '../blog/latest-posts';

const BlogSection = () => {
  return (
    <Outer>
      <Inner>
        <Flex flexDirection="column">
          <H2 textAlign="center" color="black">
            <Link to="/blog">Sprintlio Blog</Link>
          </H2>
          <H5 mt="8px" variant="body1" textAlign="center" maxWidth={590}>
            <Link to="/blog">
              Learnings, inspiration, and behind-the-scenes about remote agile retrospective
              best-practices and tools, from our team to yours.
            </Link>
          </H5>
        </Flex>
        <LatestPosts mt={40} />
      </Inner>
    </Outer>
  );
};

export default BlogSection;
