import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

import Header from '.';

const HomeHeader = () => {
  const [scrolled, setScrolled] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const checkOffScreen = useCallback(
    debounce(
      () => {
        if (window.scrollY > 100) {
          setScrolled(true);
        } else {
          setScrolled(false);
        }
      },
      50,
      { leading: true, trailing: true, maxWait: 50 }
    ),
    [setScrolled]
  );
  useEffect(() => {
    window.addEventListener('scroll', checkOffScreen);
    return () => {
      window.removeEventListener('scroll', checkOffScreen);
    };
  }, [checkOffScreen]);

  return (
    <Header bg={scrolled ? 'indigo' : 'transparent'} position={scrolled ? 'fixed' : 'absolute'} />
  );
};

HomeHeader.propTypes = {};

export default HomeHeader;
