import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import React from 'react';

import { Box, Flex, H2, H4, Inner, Outer, P } from '../styled';

const FEATURE_DATA = graphql`
  {
    allFeaturesJson {
      edges {
        node {
          id
          title
          body
        }
      }
    }
    featureImgOne: file(relativePath: { eq: "features/features1.png" }) {
      childImageSharp {
        fluid(maxWidth: 515) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    featureImgTwo: file(relativePath: { eq: "features/features2.png" }) {
      childImageSharp {
        fluid(maxWidth: 515) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const SectionTitle = (props) => <H2 color="black" textAlign={['left']} {...props} />;
const FeatureImgBlock = (props) => (
  <Flex
    flexDirection={{ _: 'column', home: 'row' }}
    justifyContent="space-between"
    alignItems={{ _: 'center', home: 'flex-start' }}
    {...props}
  />
);
const CopyWrap = (props) => (
  <Box maxWidth={{ _: 'none', home: 485 }} mt={{ _: 40, home: 0 }} {...props} />
);
const ImageWrap = (props) => (
  <Box width="100%" maxWidth={515} flex={{ _: '1', home: '1 0 515px' }} {...props} />
);

const Feature = ({ title, body, ...props }) => (
  <Box mt={30} maxWidth={{ _: 'none', home: 400 }} {...props}>
    <H4 variant="header3Med">{title}</H4>
    <P variant="body1" mt="5px">
      {body}
    </P>
  </Box>
);
Feature.propTypes = { title: PropTypes.string.isRequired, body: PropTypes.string.isRequired };

export default (props) => {
  const { allFeaturesJson, featureImgOne, featureImgTwo } = useStaticQuery(FEATURE_DATA);
  return (
    <Outer id="retrospective-features" bg="white" {...props}>
      <Inner py={{ _: 70, home: 100 }}>
        <FeatureImgBlock>
          <CopyWrap pr={{ _: 0, home: 30 }}>
            <SectionTitle>
              Agile retrospective tools for remote teams should be a lot more than digital sticky
              notes.
            </SectionTitle>
            {allFeaturesJson.edges.slice(0, 4).map(({ node }) => (
              <Feature key={node.id} {...node} />
            ))}
          </CopyWrap>
          <ImageWrap order={{ _: -1, home: 0 }}>
            <Img
              fluid={featureImgOne.childImageSharp.fluid}
              alt="Sprintlio agile retrospectives product images with team health check, cards, lists, votes, dot voting, attachments, and timer"
            />
          </ImageWrap>
        </FeatureImgBlock>
        <FeatureImgBlock mt={90}>
          <ImageWrap>
            <Img
              fluid={featureImgTwo.childImageSharp.fluid}
              alt="Sprintlio agile retrospectives action items, analytics, jira integration, and slack integration"
            />
          </ImageWrap>
          <CopyWrap pl={{ _: 0, home: 30 }}>
            <SectionTitle>
              Automate recaps and ensure accountability for your team&#39;s agile retrospectives.
            </SectionTitle>
            {allFeaturesJson.edges.slice(4).map(({ node }) => (
              <Feature key={node.id} {...node} />
            ))}
          </CopyWrap>
        </FeatureImgBlock>
      </Inner>
    </Outer>
  );
};
