import React from 'react';

import { Box, H1, H3, Inner, Outer } from '../styled';
import Logos from '../logos';
import mainBackground from '../../images/bg_main.svg';

export default () => (
  <Outer
    pt={[120, 120, 175]}
    bg="indigo"
    color="white"
    sx={{
      backgroundImage: `url(${mainBackground})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    }}
  >
    <Inner
      display="flex"
      flexDirection={{ _: 'column', home: 'row' }}
      justifyContent="space-between"
      alignItems={{ _: 'center', home: 'flex-start' }}
      pt={0}
    >
      <Box
        width="100%"
        flex="1 1 0"
        pr={{ _: '0', home: 10 }}
        textAlign={{ _: 'center', home: 'left' }}
        maxWidth={{ _: 'none', home: '500px' }}
      >
        <H1 fontSize="3.4rem">Agile retrospectives for healthier teams</H1>
        <H3 variant="header2" mt={25}>
          Powering sprint retrospectives for thousands of teams. Sprintlio elevates discussion and
          automates accountability (Slack / JIRA integrations, recaps, team health, analytics, and
          more) to empower growth.
        </H3>
      </Box>
      <Logos mt={{ _: 60, home: 0 }} maxWidth={{ _: 'none', home: '455px' }} />
    </Inner>
  </Outer>
);
