import React from 'react';

import BlogSection from '../components/home/blog-section';
import Features from '../components/home/features';
import HomeLayout from '../components/layout/HomeLayout';
import Jumbotron from '../components/home/jumbotron';

const IndexPage = () => (
  <HomeLayout seoProps={{ title: 'Agile retrospectives for healthier teams' }}>
    <Jumbotron />
    <Features />
    <BlogSection />
  </HomeLayout>
);

export default IndexPage;
