import PropTypes from 'prop-types';
import React from 'react';

import BaseLayout from './BaseLayout';
import Footer from '../footer';
import HomeHeader from '../header/home-header';

const HomeLayout = ({ seoProps, children }) => (
  <BaseLayout seoProps={seoProps}>
    <HomeHeader />
    {children}
    <Footer />
  </BaseLayout>
);

HomeLayout.propTypes = {
  children: PropTypes.node.isRequired,
  seoProps: PropTypes.shape({}),
};
HomeLayout.defaultProps = {
  seoProps: {},
};

export default HomeLayout;
