import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import BlogPostGrid from './post-grid';

const THREE_LATEST_POSTS_QUERY = graphql`
  query {
    allMdx(limit: 3, sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          ...BlogPostsData
        }
      }
    }
  }
`;

const LatestPosts = (props) => {
  const {
    allMdx: { edges: posts },
  } = useStaticQuery(THREE_LATEST_POSTS_QUERY);
  return <BlogPostGrid posts={posts} {...props} />;
};

export default LatestPosts;
